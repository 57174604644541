export const display = {
    default: {
        _seo: false,
        title: {
            tag: "h1",
            region: "hero",
            iconBefore: { icon: "regular/faArrowAltCircleRight", size: 24 },
        },
        thumb: false,
        summary: false,
        promoted: false,
        cat: false,
        person: false,
        state: false,
        year: false,
        showTime: false,
        mdate: false,
        position: false,
        header: { region: "header", bg: true, proportional: true },
        docList: {
            cat: true,
            //_layout: ["center", "body", "cat"]
        },
    },
    teaser: {
        title: { link: true },
        subtitle: true,
        summary: true,
        thumb: true,
        date: true,
        firstName: true,
        lastName: true,
        year: true
    },
    promoted: {
        title: true,
        thumb: true,
    },
}
