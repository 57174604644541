import React from "react"
import {useQuery} from "lib"
import {DocumentRow} from "./DocList"

const reverse = {
    '624f4e9e1a8ac51d52ff2374': { // proiect
        hotarare: {
            cat: "5e11158b00ab012e20b323dc",
            label: "Hotărâre",
            ref: "project.ref",
            single: true
        },
        sedinta: {
            cat: "5e11158b00ab012e20b323dd",
            label: "Ședință",
            ref: "projects.ref",
            single: true
        }
    },
    "5e11158b00ab012e20b323dc": {
        sedinta: {
            cat: "5e11158b00ab012e20b323dd",
            label: "Ședință",
            ref: "mdate",
            refField: "date",
            single: true
        }
    },
    "5e11158b00ab012e20b323dd": { // sedinta
        pv: {
            cat: "5e11158b00ab012e20b323d5",
            label: "Proces verbal ședință",
            ref: "date",
            refField: "mdate",
            single: true
        },
        minuta: {
            cat: "628cf43d045de4b4063b03fc",
            label: "Minuta ședință",
            ref: "date",
            refField: "mdate",
            single: true
        }
    },
    "628cf43d045de4b4063b03fc": { // minuta
        sedinta: {
            cat: "5e11158b00ab012e20b323dd",
            label: "Ședință",
            ref: "mdate",
            refField: "date",
            single: true
        },
        pv: {
            cat: "5e11158b00ab012e20b323d5",
            label: "Proces verbal ședință",
            ref: "date",
            refField: "date",
            single: true
        }
    },
    "5e11158b00ab012e20b323d5": { // proces verbal
        sedinta: {
            cat: "5e11158b00ab012e20b323dd",
            label: "Ședință",
            ref: "mdate",
            refField: "date",
            single: true
        },
        minuta: {
            cat: "628cf43d045de4b4063b03fc",
            label: "Minuta ședință",
            ref: "date",
            refField: "date",
            single: true
        }
    }

}
/*const useReverse = doc => {

    console.log(doc)
    return reverse[doc.cat?.ref]
}*/
const DocReverseField = ({info, field, fieldInfo, id, entity}) => {
    const q = React.useMemo(() => {
        return {
            collection: "node",
            query: {
                "cat.ref": fieldInfo.cat,
                [fieldInfo.ref]: fieldInfo.refField?entity[fieldInfo.refField]:id
            }
        }
    }, [fieldInfo, id, entity])
    const [docs] = useQuery(q)

    if(!docs||docs.length===0) return null
    return (
        <div>
            <strong>{fieldInfo.label}</strong>
            {docs.map((doc, i) => (
                <DocumentRow key={i} info={info} entity={doc} docList={docs}/>
            ))}
        </div>
    )
}

const DocReverse = ({info, entity}) => {
    const reverseFields = reverse[entity.cat?.ref]
    if(!reverseFields) return null
    return (
        <div className="reverse">
            {Object.keys(reverseFields).map((field, i) => (
                <DocReverseField key={i}
                                 info={info}
                                 field={field}
                                 fieldInfo={reverseFields[field]}
                                 id={entity._id.toString()}
                                 entity={entity}
                />
            ))}
        </div>
    )
}
//export {useReverse}
export default DocReverse
