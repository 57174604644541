import React from "react"
import { AuthButton, EntityView, Field, useQuery } from "lib"

const footerQuery = {
    collection: "site",
    query: {
        type: "footer",
    },
}
const useFooter = () => {
    const [data] = useQuery(footerQuery)
    if (!data || data.length === 0) return null
    return data[0]
}
const Footer = ({ node, language }) => {
    const footer = useFooter()

    return (
        <footer id="footer">
            <div className="footer-in">
                {footer && (
                    <EntityView entity={footer}>
                        {info => (
                            <>
                                <Field info={info} field="text" />{" "}
                                <Field info={info} field="contact" />
                            </>
                        )}
                    </EntityView>
                )}
            </div>

            <div className="copyright">
                Copyright © 2000-{new Date().getFullYear()} Primăria comunei Romuli
            </div>

            <AuthButton />
        </footer>
    )
}
export default React.memo(Footer)
