import React, { useRef, useLayoutEffect } from "react"

import { C, History, publish } from "lib"
import { nodeLayout, DefaultLayout } from "components"

const NodeContext = React.createContext({
    node: null,
    status: "exited",
})
const ALL = ["status", "location", "search", "language", "node", "first"]
const useNodeContextProvider = props => {
    const subscriptions = useRef([])
    const state = useRef({
        subscribe: (subscribeTopic, fn) => {
            const topics = subscribeTopic ? subscribeTopic.split(",") : "ALL"
            const subscriptionId = Date.now()
            subscriptions.current.push({ subscriptionId, topics, fn })
            return subscriptionId
        },
        unsubscribe: subscriptionId => {
            subscriptions.current = subscriptions.current.filter(
                item => item.subscriptionId !== subscriptionId
            )
        },
    })
    const changes = []
    Object.keys(props)
        .filter(key => key !== "children")
        .forEach(key => {
            if (!Object.is(state.current[key], props[key])) {
                state.current[key] = props[key]
                changes.push(key)
            }
        })
    subscriptions.current.forEach(item => {
        if (item.topics === "ALL") {
            item.fn(ALL.map(key => props[key]))
        } else {
            if (item.topics.filter(topic => changes.indexOf(topic) >= 0).length > 0) {
                if (item.topics.length === 1) item.fn(props[item.topics])
                else item.fn(item.topics.map(key => props[key]))
            }
        }
    })
    return state.current
}
const NodeInner = props => {
    const { node, status, first, language } = props

    const state = useNodeContextProvider(props)
    const ref = useRef()
    const prevState = useRef()

    useLayoutEffect(() => {
        if (prevState.current !== status && status === "entered") {
            publish("title", node.title)
        }

        if (status === "entering") {
            const oldNode = document.querySelector(".node-content.node-exiting")
            if (!first && oldNode) {
                const oldPage = History.getPrevPage()
                const oldScroll = window.scrollY

                if (oldPage) oldPage.scroll = oldScroll

                let sameNode = false
                if (C.LANGUAGES) {
                    if (
                        oldPage &&
                        node &&
                        node.path &&
                        node.path.filter(path => path.p === oldPage.pathname).length > 0
                    ) {
                        // same node; don't scroll
                        sameNode = true
                    }
                }

                const newPage = History.getLocation() //window.history.state
                const newScroll = sameNode
                    ? oldScroll
                    : newPage && newPage.scroll
                    ? newPage.scroll
                    : 0
                //console.log(oldScroll, newScroll, sameNode, newPage, newPage.scroll)
                /*
                const oldPageMenu = oldNode.querySelector(".page-menu-container")
                if (oldPageMenu) {
                    const delta =
                        oldPageMenu.getBoundingClientRect().top -
                        oldNode.querySelector(".region-center").getBoundingClientRect().top
                    oldPageMenu.style.transform = `translate(0, ${delta}px)`
                }*/

                if (ref.current) {
                    ref.current.style.position = "relative"
                    ref.current.style.transform = "none"
                }
                oldNode.style.position = "absolute"
                oldNode.style.width = "100%"
                oldNode.style.transform = `translate(0, ${newScroll - oldScroll}px)`
                ref.current.style.position = "relative"
                ref.current.style.transform = "none"
                window.scrollTo(0, newScroll)
            } else {
                if (ref.current) {
                    ref.current.style.position = "relative"
                    ref.current.style.transform = "none"
                }
            }
        }

        prevState.current = status
    }, [node, status, first])

    if (!node) return null

    const NodeLayout = nodeLayout[node.type] || DefaultLayout
    let classes = [`node-content node-${node.type} node-${status}`]
    if (node.section) classes.push(`section-${node.section}`)
    if (node._c && node._c._class) classes.push(node._c._class)
    let args = {}
    if (node._c && node._c._cssid) args["id"] = node._c._cssid
    //console.log("RENDER NODE INNER", node)
    //if (status === "entered") console.log(node)
    return (
        <div ref={ref} className={classes.join(" ")} {...args}>
            <NodeContext.Provider value={state}>
                <NodeLayout entity={node} language={language} />
            </NodeContext.Provider>
        </div>
    )
}
export { NodeContext }
export default React.memo(NodeInner)
