import React from "react"
import { useHistory, Link } from "lib"
import { useMainMenu } from "../header/MainMenu"

const findRecursive = (menu, searchLocation) => {
    const { p, title, items } = menu
    if (p === searchLocation) {
        return [{ p, title }]
    }
    if (!items) return null

    const section = [{ p, title }]
    for (let i in items) {
        let subsection = findRecursive(items[i], searchLocation)
        if (subsection) return section.concat(subsection)
    }
    return null
}

const useSections = pathname => {
    const menu = useMainMenu()
    if (!menu) return null
    //const location = entity.path
    //console.log(menu, location)
    if(!pathname) return null
    let toks = pathname.split("/")
    toks.pop()
    if(/^\d+$/.test(toks[toks.length-1])) toks.pop()
    if(/^\d+$/.test(toks[toks.length-1])) toks.pop()
    //console.log(toks, menu)
    const searchLocation = toks.join("/")

    let sections = null
    for (let i in menu) {
        sections = findRecursive(menu[i], searchLocation)
        if (sections) return sections
    }
    return null
}

const Breadcrumb = () => {
    const history = useHistory()
    const sections = useSections(history.location.pathname)

    return (
        <div className="breadcrumb">
            {sections &&
                sections.map((section, i) => (
                    <div key={i} className="item">
                        <Link to={section.p}>{section.title}</Link>
                    </div>
                ))}
        </div>
    )
}
export default React.memo(Breadcrumb)
