import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "serviceWorker"
import { LanguageManager, Auth, Query } from "lib"
import Location from "lib/app/Location"
import { HelmetProvider } from "react-helmet-async"

Bugsnag.start({
    apiKey: "371a0e04bcf80f9996bfc777d960d1ea",
    plugins: [new BugsnagPluginReact(React)],
})
//const bugsnagClient = bugsnag("371a0e04bcf80f9996bfc777d960d1ea")
//bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = Bugsnag.getPlugin("react")
if (window.initialStore) window.isHydrating = true
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.hydrate(
    <ErrorBoundary>
        <HelmetProvider>
            <LanguageManager>
                <Auth>
                    <Location />
                </Auth>
            </LanguageManager>
        </HelmetProvider>
    </ErrorBoundary>,
    document.getElementById("root"),
    () => {
        window.isHydrating = false
        delete window.initialStore
        setTimeout(() => {
            Query.refresh()
        }, 5000)
    }
)

serviceWorker.register({
    onUpdate: async registration => {
        // We want to run this code only if we detect a new service worker is
        // waiting to be activated.
        // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
        //window.location.reload()
        if (registration && registration.waiting) {
            console.log("update")
            //registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            await registration.unregister()
            // Once the service worker is unregistered, we can reload the page to let
            // the browser download a fresh copy of our app (invalidating the cache)
            window.location.reload()
        }
    },
})
