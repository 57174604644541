import React from "react"
import axios from "axios"
import {
    useHistory,
    Pager,
    pagerToFirstPage,
    EntityView,
    Field,
    FaIcon,
    useQuery,
    flatten,
    realPath,
    useSubscription,
    Entity
} from "lib"
import DocReverse from "./DocReverse"

const extra = {
    "5e11158b00ab012e20b323dd": ["projects"],
    "5e11158b00ab012e20b323dc": ["project"],
    '624f4e9e1a8ac51d52ff2374': ["materiale"],
    all: ["projects", "project", "materiale"]
}
//import { documentStateTypes } from "conf/config"
/*
const renderState = state => {
    const s = documentStateTypes.filter(s => s.val === state)
    const label = s.length > 0 ? s[0].label : ""
    return <div className={`state state-${state}`}>{label}</div>
}*/
/*const renderDownload = entity => {
    return (
        <div className="download-list">
            {entity.docs &&
                entity.docs.map((doc, i) => (
                    <a key={i} href={realPath(doc.url)} title={doc.name} className="download">
                        <FaIcon icon="faFileDownload" size="24" />
                    </a>
                ))}
        </div>
    )
    }*/
const Search = ({value, onChange}) => {
    const [focus, setFocus] = React.useState()
    const handleFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const args = {}
    if(!value) args["is-empty"] = ""
    if(focus) args["is-focus"] = ""
    return (
        <div className="sp-search-form-field" {...args}>
            <label htmlFor="sp-search">Caută documente...</label>
            <input id="sp-search" type="search" name="sp-search" value={value} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur}/>
        </div>
    )
}

const solrSearch = async (input, cat, years, page, pageSize) => {
    let query = `(title:${input}~1 OR subtitle:${input}~1)`
    if(years.length>0) {
        query += " AND (" + years.map(y => `year:${y}`).join(" OR ") + ")"
    }
    if (cat) {
        if(Array.isArray(cat)) query += " AND (" +cat.map(c => `catid:${cat._id.toString()}`).join(" OR ") + ")"
        else query += ` AND catid:${cat.ref}`
        //query += ` AND category:${cat.title}`
    }
    //console.log(cat, years, page, pageSize, cat)
    const offset = (page-1) * pageSize
    const res = await axios.post("search", {
        query,
        fields: ["id"],
        sort: "date desc, score desc",
        offset,
    })
    const {response, error} = res.data
    //console.log(query, response, error)
    if(error) {
        console.log(error)
        return null
    }
    //const {numFound, docs} = response
    return response
}

const buildSimpleQuery = (cat, years, page, pageSize) => {
    let qYear = {}

    try {
        qYear =
            years && years.length > 0
            ? {
                year: {
                    $in: flatten(
                        years.map(y =>
                            y === "NaN" ? [NaN, null, undefined] : parseInt(y, 10)
                        )
                    ),
                },
            }
        : {}
    } catch (e) {
        console.log(e)
    }

    if (cat && Array.isArray(cat)) {
        return {
            collection: "node",
            query: {
                type: "document",
                "cat.ref": {$in: cat.map(c => c._id.toString())},
                ...qYear,
            },
            limit: pageSize,
            skip: (page - 1) * pageSize,
            sort: { date: -1, _id: -1 },
        }
    }
    return cat && cat.ref
        ? {
            collection: "node",
            query: {
                type: "document",
                "cat.ref": cat.ref,
                ...qYear,
            },
            /*projection: {
                title: 1,
                subtitle: 1,
                type: 1,
                path: 1,
                date: 1,
                showTime: 1,
                state: 1,
                docs: 1,
                cat: 1,
                ...(cat?.hasperson ? { person: 1, year: 1 } : {}),
                ...(extra[cat.ref]?extra[cat.ref].reduce((acc, item) => {
                    acc[item] = 1
                    return acc
                }, {}):{})
            },*/
            limit: pageSize,
            skip: (page - 1) * pageSize,
            sort: { date: -1, _id: -1 },
        }
    : null

}
const buildSolrQuery = (res, cat, page, pageSize) => {
    const {numFound, docs} = res
    //console.log(numFound, docs)
    if(numFound===0||!docs) return null
    //const pageDocs = docs.slice((page-1)*pageSize, pageSize)
    return {
        collection: "node",
        query: {_id: {$in: docs.map(doc => doc.id)}},
        /*projection: {
            title: 1,
            subtitle: 1,
            type: 1,
            path: 1,
            date: 1,
            showTime: 1,
            state: 1,
            docs: 1,
            cat: 1,
            ...(cat.hasperson ? { person: 1, year: 1 } : {}),
            ...(extra[cat.ref]?extra[cat.ref].reduce((acc, item) => {
                acc[item] = 1
                return acc
            }, {}):{})
        },*/
        sort: { date: -1, _id: -1 },
    }
}

const useData = state => {
    const [localState, setLocalState] = React.useState({})
    const { cat, input, years, page, pageSize } = state

    const localInput = React.useRef()
    const search = React.useCallback(() => {
        const doSolrSearch = async () => {
            const res = await solrSearch(localInput.current, cat, years, page, pageSize)
            if(input!==localInput.current||!res) return
            setLocalState(state => ({...state, res}))
        }

        doSolrSearch()
    }, [input, cat, years, page, pageSize])
    React.useEffect(() => {
        if(localInput.current === input) return
        localInput.current = input
        if(!input)
            setLocalState(state => ({...state, res: null}))
        //search()
    }, [input])
    React.useEffect(() => {
        if(!localInput.current) return
        search()
    }, [page, pageSize, search])
    /*React.useEffect(() => {
        const q = buildSimpleQuery(cat, years, page, pageSize)
        setLocalState(state => ({...state, q}))
    }, [cat, years, page, pageSize])
    //console.log(q)
    */

    const q = React.useMemo(() => {
        if(localState.res)
            return buildSolrQuery(localState.res, cat, page, pageSize)
        return buildSimpleQuery(cat, years, page, pageSize)
    }, [localState.res, cat, years, page, pageSize])

    const result = useQuery(q)
    return localState.res
        ?[result[0], result[1], localState.res.numFound]
        :result
}
const useAllYears = cat => {
    const q = React.useMemo(
        () => ({
            collection: "node",
            pipeline: [
                {
                    $match: {
                        type: "document",
                        "cat.ref": Array.isArray(cat)?{$in: cat.map(c => c._id.toString())}:cat?.ref,
                    },
                },
                { $sort: { year: 1 } },
                { $project: { year: 1 } },
                {
                    $group: {
                        _id: "$year",
                        count: { $sum: 1 },
                    },
                },
                { $sort: { _id: -1 } },
            ],
        }),
        [cat]
    )
    //console.log(q)
    return useQuery(q)
}
const searchRemoveYear = search =>
    search
        ? Object.keys(search)
              .filter(key => key !== "year")
              .reduce((acc, key) => ({ ...acc, [key]: search[key] }), {})
        : null

const initYearFilter = history =>
    history.location.search.year ? history.location.search.year.split(",") : []
const YearFilter = ({ history, cat, onChange }) => {
    const [allYears] = useAllYears(cat)
    const [years, setYears] = React.useState(() => initYearFilter(history))
    const handleYearToggle = React.useCallback(
        e => {
            const year = e.currentTarget.dataset.year
            let ys = years
            if (years.includes(year)) ys = years.filter(y => y !== year)
            else ys = [...years, year]
            if (ys.length === 0) {
                history.pushSearch({
                    ...pagerToFirstPage(searchRemoveYear(history.location.search)),
                })
            } else {
                history.pushSearch({ ...pagerToFirstPage(history.location.search), year: [ys] })
            }
            setYears(ys)
            onChange(ys)
        },
        [years, history, onChange]
    )
    if (!allYears) return null
    return (
        <div className="year-filter">
            {allYears.map(year => (
                <div
                    key={year._id}
                    data-year={isNaN(year._id) || !year._id ? "NaN" : year._id}
                    onClick={handleYearToggle}
                >
                    <FaIcon
                        icon={
                            years.includes(`${isNaN(year._id) || !year._id ? "NaN" : year._id}`)
                                ? "toggle/check_box"
                                : "toggle/check_box_outline_blank"
                        }
                        size={24}
                    />
                    <div className="year">
                        {isNaN(year._id) || !year._id ? "Fără dată" : year._id}
                    </div>
                    <div className="count">({year.count})</div>
                </div>
            ))}
        </div>
    )
}
const types = {
    pdf: "regular/faFilePdf",
    p7s: "regular/faFileArchive",
    doc: "regular/faFileWord",
    docx: "regular/faFileWord",
    xls: "regular/faFileExcel",
    xlsx: "regular/faFileExcel",
    jpg: "regular/faFileImage",
    png: "regular/faFileImage",
}
const getDocIcon = doc => {
    const ext = doc.url.split(".").pop().toLowerCase()
    //if (!types[ext]) console.log(doc, ext)
    return types[ext] || "regular/faFileAlt"
}
/*const getIcon = docs => {
    if (!docs || docs.length === 0) return "regular/faFile"
    if (docs.length > 1) return "regular/faFolder"
    return getDocIcon(docs[0])
}*/
const getName = doc => {
    if (doc.name) return doc.name
    if (doc.description) return doc.description
    if (!doc.url) return ""
    const toks = doc.url.split("/")
    return toks[toks.length - 1]
}
const DownloadIcon = ({f}) => {
    if(!f) return null
    return (
        <a href={realPath(f.url)} className="download" title="Descărcare">
            <FaIcon icon="faFileDownload" size={24} />
        </a>
    )
}
const DownloadLink = ({doc}) => (
    <div className="doc-row-file">
        <a
            href={realPath(doc.url)}
            target="_blank"
            rel="noopener noreferrer"
            className="download"
            download
        >
            <FaIcon icon={getDocIcon(doc)} size={18} />
        </a>
        <a
            href={realPath(doc.url)}
            target="_blank"
            rel="noopener noreferrer"
            className="download"
            download
        >
            {getName(doc)}
        </a>
    </div>
)

const ExtraDocumentList = ({info, value}) => {
    const q = React.useMemo(() => {
        return {
            collection: "node",
            query: {_id: {$in: value.map(doc => doc.ref)}},
            /*projection: {
                title: 1,
                subtitle: 1,
                type: 1,
                path: 1,
                date: 1,
                showTime: 1,
                state: 1,
                docs: 1,
                cat: 1,
                person: 1, year: 1,
                ...(extra.all.reduce((acc, item) => {
                    acc[item] = 1
                    return acc
                }, {}))
            },*/
            sort: { date: -1, _id: -1 },
        }
    }, [value])
    const [docs] = useQuery(q)
    if(!docs) return null

    return (
        <>
            {docs.map((doc, i) => <DocumentRow key={i} info={info} entity={doc} docList={value}/>)}
        </>
    )
}

const ExtraDocument = ({info, value}) => {
    const q = React.useMemo(() => {
        return {
            collection: "node",
            query: {_id: value.ref},
        }
    }, [value])
    const [docs] = useQuery(q)
    const doc = docs?.[0]
    if(!doc) return null

    return <DocumentRow info={info} entity={doc} docList={value}/>
}

const ExtraField = ({info, field, fieldInfo, value}) => {
    if(!value) return null

    if (fieldInfo.type==="list") {
        if (fieldInfo.items.type==="ref" && fieldInfo.items.ref==="document") {
            return (
                <div className={field}>
                    <strong>{fieldInfo.label}</strong>
                    <ExtraDocumentList info={info} value={value}/>
                </div>
            )
        }
    }
    if (fieldInfo.typeName==="ref" && fieldInfo.ref==="document") {
        return (
            <div className={field}>
                <strong>{fieldInfo.label}</strong>
                <ExtraDocument info={info} value={value}/>
            </div>
        )
    }
    if (fieldInfo.typeName==="doc") {
        return (
            <div className={field}>
                <strong>{fieldInfo.label}</strong>
                {value.map((doc, j) => <DownloadLink key={j} doc={doc}/>)}
            </div>
        )
    }
    console.log(fieldInfo, value)

    return null
}

const ExtraFields = ({info, entity}) => {
    const fields = extra[entity.cat.ref]
    if(!fields) return null
    return (
        <>
            {fields.map((f, i) => {
                const value = entity[f]
                if(!value) return null
                const fieldInfo = Entity.getTypeInfo(f, entity)
                return <ExtraField key={i} info={info} field={f} fieldInfo={fieldInfo} value={value}/>
            })}
        </>
    )
}
/*const ReverseFields = ({info, entity}) => {
    const reverse = useReverse(entity)
    return null

    const fields = extra[entity.cat.ref]
    if(!fields) return null
    return (
        <>
            {fields.map((f, i) => {
                const value = entity[f]
                if(!value) return null
                const fieldInfo = Entity.getTypeInfo(f, entity)
                return <ExtraField key={i} info={info} field={f} fieldInfo={fieldInfo} value={value}/>
            })}
        </>
    )
}*/

const DocumentRow = ({ info, entity, docList }) => {
    const [open, setOpen] = React.useState(false)
    const toggle = React.useCallback(() => {
        setOpen(v => !v)
    }, [])
    //const n = entity?.docs?.length ?? 0

    return (
        <EntityView info={info} entity={entity} className="row" display="teaser">
            {info => (
                <>
                    <div className="doc-row-icon">
                        <div className="doc-row-toggle" onClick={toggle}>
                            <FaIcon icon={open ? "faCaretDown" : "faCaretRight"} size={24} />
                        </div>
                    </div>
                    <Field info={info} field="date" />
                    <Field info={info} field="title" />
                    <DownloadIcon f={entity.docs?.[0]}/>

                    {docList?.hasperson ? (
                        <div field-person="" type-person="">
                            <Field info={info} field="person.firstName" noadmin />
                            <Field info={info} field="person.lastName" noadmin />
                            <Field info={info} pre="(" post=")" field="year" noadmin />
                        </div>
                    ) : (
                        <Field info={info} field="subtitle" />
                    )}
                    {open && (
                        <>
                        <div className="doc-row-files">
                            {entity.docs.map((doc, i) => (
                                <DownloadLink key={i} doc={doc}/>
                            ))}
                        </div>
                            <ExtraFields info={info} entity={entity}/>
                            <DocReverse info={info} entity={entity}/>
                        </>
                    )}
                </>
            )}
        </EntityView>
    )
}

const initState = (cat, history) => {
    const search = history.location.search
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const input = (search && search.search)?search.search:""
    const pageSize = 10
    return {
        cat,
        years: search.year ? search.year.split(",") : [],
        page,
        pageSize,
        input
    }
}

const useAlias = cat => {

    const q = React.useMemo(() => {
        return Array.isArray(cat)?null:{
            collection: "cat",
            query: {type: "category", _id: cat?.ref}
        }
    }, [cat])
    const [results] = useQuery(q)
    if (results?.length>0 && results[0].alias) {
        return results[0].alias
    }
    return cat
}

const DocList = ({ domRef, info, value, ...props }) => {
    const history = useHistory()
    const [state, setState] = React.useState(() => initState(value, history))
    const newCat = useAlias(state.cat)
    useSubscription("cat", (topic, cat) => {
        //console.log("cat")
        setState(state => state.cat===cat?state:{...state, cat, page: 1})
    })

    React.useEffect(() => {
        setState(state => ({...state, cat: newCat}))
    }, [newCat])
    /*React.useEffect(() => {
        if(!tabCat) return
        setState(state => ({...state, cat: tabCat}))
    }, [tabCat])*/
    const [data, , total] = useData(state)
    const handleYearChange = React.useCallback(years => {
        //console.log("year")
        setState(state => ({ ...state, years, page: 1 }))
    }, [])
    const handleInputChange = React.useCallback(e => {
        //console.log("input")
        const input = e.target.value
        setState(state => ({ ...state, input, page: 1 }))
    }, [])
    const handlePageChange = React.useCallback(page => {
        setState(state => ({ ...state, page }))
    }, [])
    //console.log(data, total)
    //if (!data) return null
    //console.log(data)
    //if(!state.cat) return null
    //console.log("CAT:", state.cat)
    return (
        <div ref={domRef} {...props}>
            <YearFilter history={history} cat={state.cat} onChange={handleYearChange} />
            <Search value={state.input} onChange={handleInputChange}/>
            {data && <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
                     />}
            {data?.map((e, i) => (
                <DocumentRow info={info} entity={e} docList={value} key={i} />
            ))}
            {data && <Pager
                page={state.page}
                total={total}
                pageSize={state.pageSize}
                onChange={handlePageChange}
                     />}
        </div>
    )
}
export default React.memo(DocList)
export {DocumentRow}
