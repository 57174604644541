import React from "react"
import { useQuery, Link, FaIcon } from "lib"

const mainMenuQuery = {
    collection: "menu",
    query: {
        label: "Meniu principal",
    },
}
export const useMainMenu = () => {
    const [mainMenu] = useQuery(mainMenuQuery, { single: true, tag: "mainMenu" })
    return mainMenu ? mainMenu.items : []
}

const MenuItem = ({item, level, path, language, onActivate}) => {
    const hasI18n = item._i18n
        ? Object.keys(item._i18n).filter(l => l === language).length > 0
        : false
    const data = hasI18n ? item._i18n[language] : item
    const items = item.items?.filter(it => !it.h)??[]
    return (
            <li className={items && items.length > 0 ? "has-submenu" : ""} menu-path={path.join('-')}>
            <Link to={data.p} onActivate={onActivate}>
            {data.title}
        {level>0 && items && items.length > 0 && <FaIcon icon="faCaretRight" size={12}/>}
            </Link>
            {items && items.length > 0 && (
                <ul className="menu">
                    {items.map((item, i) => <MenuItem key={i} item={item} path={[...path, i]} level={level+1} language={language} onActivate={onActivate}/>)}
                </ul>
            )}
        </li>
    )
}

const MainMenu = ({ id, menu, language, onActivate }) => (
    <ul id={id} className="main-menu">
        {menu.filter(item => !item.h).map((item, i) => <MenuItem key={i} level={0} path={[0]} item={item} language={language} onActivate={onActivate}/>)}
    </ul>
)

export default React.memo(MainMenu)
