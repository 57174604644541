import React from "react"
import { useSubscriptionProvider, useHistory } from "lib"

const tabs = {
    hotarari: [
        "Hotărâri",
        "Proiecte de hotărâri",
        "Registru evidență proiecte de hotărâri", "Registru evidență hotărâri", "Arhivă"
    ]
}
const cats = [
    {
        "ref": "5e11158b00ab012e20b323dc",
        "title": "Hotărâri ale Consiliului Local",
        "path_info": "/consiliul-local/hotarari-ale-consiliului-local"
    }, {
        "ref": "624f4e9e1a8ac51d52ff2374",
        "title": "Hotărâri ale Consiliului Local",
        "path_info": "/consiliul-local/proiecte-de-hotarari-ale-consiliului-local"
    },
    {
        "ref": "624f4e9e1a8ac51d52ff2374",
        "title": "Hotărâri ale Consiliului Local",
        "path_info": "/consiliul-local/proiecte-de-hotarari-ale-consiliului-local"
    },
    {
        "ref": "5e11158b00ab012e20b323dc",
        "title": "Hotărâri ale Consiliului Local",
        "path_info": "/consiliul-local/hotarari-ale-consiliului-local"
    },{
        "ref": "5e11158b00ab012e20b323dc",
        "title": "Hotărâri ale Consiliului Local",
        "path_info": "/consiliul-local/hotarari-ale-consiliului-local"
    },
    ]

const TabList = ({ domRef, info, value, ...props }) => {
    const history = useHistory()
    const [state, setState] = React.useState(() => {
        const pathname = history.location.pathname
        for(let i=0;i<cats.length;i++) {
            if(cats[i].path_info===pathname) return i
        }
        return 0
    })
    const publish = useSubscriptionProvider("cat")
    const handleClick = React.useCallback(e => {
        const index = parseInt(e.target.dataset.index)
        setState(index)
        history.push(cats[index].path_info)
    }, [history])
    React.useEffect(() => {
        //console.log("publish", cats[state])
        publish(cats[state])
    }, [publish, state])
    //console.log(info)
    if (!value||!tabs[value]) return null
    const tabLabels = tabs[value]

    return (
        <>
            {tabLabels.map((t, i) => (
                <div key={i} className={`tab${i===state?" active":""}`} onClick={handleClick} data-index={i}>
                    {t}
                </div>
            ))}
        </>
    )
}
export default React.memo(TabList)
