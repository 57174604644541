import axios from "axios"
import { History } from "../history"
import { Query } from "../query"
import C from "../conf"
import { getCollection, is, getTypeInfo } from "./type_info"
import { types } from "./types"
import { getId, get, set } from "./accessors"
import customFieldAdmin from "conf/fieldAdmin"
import { ObjectId } from "bson"

export const isNew = e => e && e._id && !e._custom && !(e._id instanceof ObjectId)

const fieldAdmin = {}
Object.keys(customFieldAdmin).forEach(key => {
    if (fieldAdmin[key]) fieldAdmin[key] = { ...fieldAdmin[key], ...customFieldAdmin[key] }
    else fieldAdmin[key] = customFieldAdmin[key]
})
function* refGenerator(entity, parentFieldName, parent, parentFieldInfo, fields) {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = parent ? get(parent, field.name) : get(entity, field.name)
        const fieldName = parentFieldName ? `${parentFieldName}.${field.name}` : field.name
        if (value) {
            if (field.type === "ref") yield [field, value, fieldName]
            else if (field.fields)
                yield* refGenerator(entity, fieldName, value, field, field.fields)
            else if (field.type === "list") {
                for (let j = 0; j < value.length; j++) {
                    const fName = `${fieldName}.${j}`
                    //const v = entity.getChildValue(parent, parentFieldInfo, `${field.name}.${j}`)
                    const fInfo = getTypeInfo(`${field.name}.${j}`, parent, parentFieldInfo)
                    if (fInfo.type === "ref") yield [fInfo, value[j], fName]
                    else if (fInfo.fields)
                        yield* refGenerator(entity, fName, value[j], fInfo, fInfo.fields)
                }
            }
        }
    }
}
const _updateRefs = async (entity, g) => {
    const { value, done } = g.next()
    if (done) return entity

    const [field, val] = value
    if (!val) return _updateRefs(entity, g)

    const refType = types[field.ref]
    const coll = refType.collection

    const refItem = {
        coll,
        ref: val.ref,
        dep: entity._id.toString(),
        depColl: getCollection(entity),
    }
    const q = {
        collection: "refs",
        query: refItem,
    }
    //console.log(entity, field, val, refItem)
    const data = await Query.select(q)
    if (!data || !data.results || data.results.length === 0) {
        await Query.insert({
            collection: "refs",
            data: refItem,
        })
    }
    return _updateRefs(entity, g)
}
const updateRefs = entity => {
    return _updateRefs(
        entity,
        refGenerator(entity, null, entity, null, getTypeInfo(entity).fields || [])
    )
}

const _updateDepEntity = async (_idRef, entity, options, depEntity, g, updates, rest) => {
    const { value, done } = g.next()
    //console.log("dep", _idRef, entity, depEntity, updates, value, done)
    if (done) {
        if (updates === 0) await axios.delete(`data/refs/${_idRef.str}`)
        else await save(depEntity, options)

        return _updateDep(entity, options, rest)
    }

    const [field, val, fieldName] = value
    const refType = types[field.ref]
    const collection = refType.collection
    if (!val || val.ref !== entity._id.toString() || collection !== getCollection(entity))
        return _updateDepEntity(_idRef, entity, options, depEntity, g, updates, rest)

    const v = { ...val }
    if (field.cache) {
        if (typeof field.cache === "string") {
            /*field.cache
                .split(",")
                .map(f => f.trim())
                .forEach(f => {
                    v[f] = f === "path" ? entity.path : get(entity, f)
                })*/

            if (!C.LANGUAGES) {
                field.cache
                    .split(",")
                    .map(s => s.trim())
                    .forEach(f => (v[f] = f === "path" ? entity.path : get(entity, f)))
            } else {
                const defaultLanguage = options.defaultLanguage ?? C.LANGUAGES[0]
                const fields = field.cache.split(",").map(s => s.trim())
                const defLang = entity._lang ?? C.LANGUAGES[0]
                fields.forEach(f => {
                    v[f] = get(entity, f, {
                        defaultLanguage: defLang,
                        language: defaultLanguage,
                    })
                })
                if (entity._i18n) {
                    v._i18n = {}
                    C.LANGUAGES.forEach(lang => {
                        if (lang === defaultLanguage) return
                        v._i18n[lang] = {}

                        fields.forEach(f => {
                            v._i18n[lang][f] = get(entity, f, {
                                defaultLanguage: defLang,
                                language: lang,
                            })
                        })
                    })
                }
            }
        } else field.cache(v, entity)
    }
    let de = set(depEntity, fieldName, v)
    //console.log(de, fieldName, depEntity.type, options)
    de = options?.entityAdmin?.[depEntity.type]?.conditional?.[fieldName]?.(de) ?? de
    //if (conditional) de = conditional(de)
    /*
        options.entityAdmin &&
        options.entityAdmin[depEntity.type] &&
        options.entityAdmin[depEntity.type].conditional &&
            options.entityAdmin[depEntity.type].conditional[fieldName]
    )
de = options.entityAdmin[depEntity.type].conditional[fieldName](de)
*/
    return _updateDepEntity(_idRef, entity, options, de, g, updates + 1, rest)
}

const _updateDep = async (entity, options, deps) => {
    if (deps.length === 0) return entity
    const [{ _id, dep, depColl }, ...rest] = deps

    const depEntity = await load(dep, depColl)
    return _updateDepEntity(
        _id,
        entity,
        options,
        depEntity,
        refGenerator(depEntity, null, depEntity, null, getTypeInfo(depEntity).fields || []),
        0,
        rest
    )
}
const updateDeps = async (entity, options) => {
    const q = {
        collection: "refs",
        query: {
            ref: entity._id.toString(),
            coll: getCollection(entity),
        },
    }

    const data = await Query.select(q)
    //console.log("deps", q, data)
    if (!data || !data.results || data.results.length === 0) return entity
    return _updateDep(entity, options, data.results)
}

const VerifyException = errors => ({ errors })
const _verifyEntity = (info, entity) => {
    let errors = {}
    if (!info) return true
    if (info.fieldCheck) {
        errors = Object.keys(info.fieldCheck)
            .map(field => ({
                field,
                errors: info.fieldCheck[field][0](entity[field], entity)
                    ? null
                    : { text: info.fieldCheck[field][1] },
            }))
            .filter(item => item.errors)
            .reduce((acc, el) => {
                acc[el.field] = el.errors
                return acc
            }, {})
    }
    info.fields
        .map(field => ({
            field: field.name,
            errors:
                fieldAdmin[field.type] && fieldAdmin[field.type].check
                    ? fieldAdmin[field.type].check[0](entity[field.name])
                        ? null
                        : { text: fieldAdmin[field.type].check[1] }
                    : null,
        }))
        .filter(item => item.errors)
        .forEach(item => {
            if (errors[item.field]) {
                if (errors[item.field].text)
                    errors[item.field].text = `${errors[item.field].text} ${item.errors.text}`
                else errors[item.field].text = item.errors.text
            } else {
                errors[item.field] = item.errors
            }
        })
    if (info.check) {
        const entityErrors = info.check(entity)
        if (entityErrors) {
            errors = { ...errors, ...entityErrors }
        }
    }
    /*
	if (Object.keys(errors).length > 0) {
			dispatch({ type: "errors", errors })
			return false
	}
	return true
	*/
    if (Object.keys(errors).length > 0) throw VerifyException(errors)
    return true
}
const optionsAddSaved = (options, entity) => ({
    ...options,
    saved: [...(options.saved || []), getId(entity)],
})
const alreadySaved = (entity, options) => options?.saved?.includes(getId(entity))
//options.saved && options.saved.includes(getId(entity))

const save = async (entityToSave, options = {}) => {
    let entity = entityToSave
    if (alreadySaved(entity, options)) return entity
    const { remove, noredirect, ...otherOptions } = options
    const info = getTypeInfo(entity)
    _verifyEntity(info, entity)
    if (info && info.presave) entity = info.presave(entity)
    const oldPath = is(entity, "node") ? entity.path : null
    //entity.presave()
    console.log("SAVE", entity)
    if (is(entity, "node")) {
        let pathauto = true
        if (entity._c && entity._c.pathauto === false) pathauto = false
        if (
            pathauto /*&&
            options.entityAdmin &&
            options.entityAdmin[entity.type] &&
            options.entityAdmin[entity.type].pathinfo*/
        ) {
            const pathinfo = options?.entityAdmin?.[entity.type]?.pathinfo
            if (pathinfo) {
                if (!entity._c) entity._c = {}
                if (typeof pathinfo === "string") entity._c.pathinfo = pathinfo
                else entity._c.pathinfo = pathinfo(entity)
            }
        }
    }
    if (isNew(entity)) {
        //if (LANGUAGES) entity._lang = state.defaultLanguage
        const { _id, ...data } = entity
        const response = await Query.insert({
            collection: info.collection,
            data,
        })

        const res = await Query.select({
            collection: info.collection,
            query: { _id: response.data.insertedId },
        })

        if (!res || !res.total === 1) {
            console.log("Just saved entity not found.")
            return null
        }
        let e = res.results[0]
        if (info.afterSave) info.afterSave(e)
        const opt = optionsAddSaved(otherOptions, e)
        opt.defaultLanguage = e._lang ?? C?.LANGUAGES?.[0]
        e = await updateRefs(e, opt)
        e = await updateDeps(e, opt)
        if (is(e, "node") && !noredirect) {
            if (!C.LANGUAGES) History.push(e.path)
            else if (e.path.length > 0) History.push(e.path[0].p)
        }
        return e
    } else {
        const { _id, $unset, ...$set } = entity
        /*console.log({
                collection: info.collection,
                data: { $set, $unset },
                remove,
                _id,
            })*/
        const response = await Query.update({
            collection: info ? info.collection : "node",
            data: { $set, $unset },
            remove: options.remove,
            _id,
        })
        //console.log(response)
        let e = entity
        if (info && info.afterSave) info.afterSave(e)
        if (response?.data?.redirect) e.path = response.data.redirect
        const opt = optionsAddSaved(otherOptions, e)
        opt.defaultLanguage = e._lang ?? C?.LANGUAGES?.[0]
        //if (!norefs) {
        e = await updateRefs(e, opt)
        e = await updateDeps(e, opt)
        //}
        if (
            typeof window !== "undefined" &&
            C.LANGUAGES === null &&
            is(e, "node") &&
            oldPath === document.location.pathname &&
            oldPath !== e.path &&
            !noredirect
        ) {
            History.push(e.path)
        }
        return e
    }
}

const create = (type, language) => {
    const info = types[type]
    if (!info) return null

    let entity = { _id: {}, type }
    if (C.LANGUAGES) {
        entity._lang = C.LANGUAGES[0]
    }
    //const info = getTypeInfo(entity)
    //entity = wrapInfo(entity)

    info.fields.forEach(field => {
        if (!field.default) return

        entity = set(entity, field.name, field.default, language)
    })
    if (info.onNew) entity = info.onNew(entity)

    return entity
}

const load = async (_id, collection = "node") => {
    const options = {
        collection,
        query: { _id },
    }

    const results = await Query.select(options)
    if (!results || !results.results || results.total < 1) return null
    return results.results[0]
    /*return axios
        .post("datamulti", { params: { options } })
        .then(response => {
            if (
                response.data &&
                response.data.length === 1 &&
                response.data[0].results &&
                response.data[0].results.length === 1
            )
                return response.data[0].results[0]
            return null
        })
        .catch(error => {
            console.log(error)
            return null
        })*/
}
const remove = entity => {
    const id = getId(entity)
    const info = getTypeInfo(entity)
    if (!id || !info) return
    const collection = info.collection || "node"
    return Query.remove(id, collection)
}

export { save, create, load, remove }
