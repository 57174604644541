import React from "react"
import { FaIcon } from "lib"
import Doc from "./Doc"

const DocRef = ({ domRef, info, value, ...props }) => {
    const [open, setOpen] = React.useState()
    //console.log(info, value, props)
    const label = info?.fieldInfo?.label
    const toggleOpen = React.useCallback(() => {
        setOpen(open => !open)
    }, [])
    React.useEffect(() => {
        if(!open) return
    }, [open])
    if(!value||value.length===0) return null
    return (
        <div ref={domRef} {...props}>
            <h3 onClick={toggleOpen} className={`toggle${open?" open":""}`}>
                <FaIcon icon="solid/faAngleRight" size={32}/>
                {label}
            </h3>
            {open &&
             <div className="docref-content">
                 <strong>{value?.title}</strong><br/>
                 {value?.subtitle}
                 <Doc info={info} value={value.docs}/>
             </div>
            }
        </div>
    )
}
export default DocRef
