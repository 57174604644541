import React from "react"
import { FaIcon } from "lib"
import Doc from "./Doc"

const DocRefList = ({ domRef, info, value, ...props }) => {
    const [open, setOpen] = React.useState(true)
    //console.log(info, value, props)
    const label = info?.fieldInfo?.label
    const toggleOpen = React.useCallback(() => {
        setOpen(open => !open)
    }, [])
    React.useEffect(() => {
        if(!open) return
    }, [open])
    
    if(!value||value.length===0) return null
    return (
        <div ref={domRef} {...props}>
            <h3 onClick={toggleOpen} className={`toggle${open?" open":""}`}>
                <FaIcon icon="solid/faAngleRight" size={32}/>
                {label}
            </h3>
            {open &&
             <div className="docref-content">
                 {value.map((val, i) => (
                     <div key={i} className="docref-item">
                         <div className="index">{i+1}</div>
                         <strong>{val?.title}</strong><br/>
                         {val?.subtitle}
                         <Doc info={info} value={val.docs}/>
                         {val.materiale &&
                          <>
                              <strong>Materiale</strong><br/>
                              <Doc info={info} value={val.materiale}/>
                              </>}
                     </div>
                 ))}
             </div>
            }
        </div>
    )
}
export default DocRefList
