import { ObjectId } from "bson"
//import C from "../conf"
import { pathFromField } from "./util"
import { getTypeInfo, $fieldInfo, $type } from "./type_info"

const buildPathRec = (p, e, info, path, i, options) => {
    if (i >= path.length) return p
    const typeInfo = getTypeInfo(`${path[i]}`, e, info)
    if (!typeInfo) {
        //console.log(p, e, info, path, i)
        return null
    }
    //console.log(p, e, info, path, i, typeInfo)
    if (typeInfo.buildPath)
        return typeInfo.buildPath(p, e, path, i, info, typeInfo, buildPathRec, options)
    if (p.length === 0)
        return buildPathRec([{ f: path[i] }], e ? e[path[i]] : null, typeInfo, path, i + 1, options)
    return buildPathRec(
        [...p, { f: path[i] }],
        e ? e[path[i]] : null,
        typeInfo,
        path,
        i + 1,
        options
    )
}
const buildPath = (entity, field, options) => {
    let info = options?.entityInfo ?? getTypeInfo(entity)
    const path = pathFromField(field)

    //console.log(field, entity)
    const p = buildPathRec([], entity, info, path, 0, options)
    //console.log(field, p, entity)
    return p
}
export const getLens = (entity, field, options) => {
    if (!entity || field == null || field === undefined) return null
    const path = buildPath(entity, field, options)
    //console.log(field, path, entity, options)
    return {
        path,
        get: entity => {
            let ret = path ? path.reduce((acc, f) => (acc ? acc[f.f] : acc), entity) : undefined
            if (
                ret ||
                !options ||
                !options.language ||
                options.language === options.defaultLanguage
            )
                return ret
            const p = buildPath(entity, field, { ...options, language: options.defaultLanguage })
            return p ? p.reduce((acc, f) => (acc ? acc[f.f] : acc), entity) : undefined
        },
        set: (entity, value) => (path ? setRec(entity, path, 0, value) : undefined),
    }
}
export const get = (entity, field, options) => {
    if (!entity || field == null || field === undefined) return null
    let path = buildPath(entity, field, options)
    let ret = path ? path.reduce((acc, f) => (acc ? acc[f.f] : acc), entity) : undefined
    if (ret || !options || !options.language || options.language === options.defaultLanguage)
        return ret
    path = buildPath(entity, field, { ...options, language: options.defaultLanguage })
    return path ? path.reduce((acc, f) => (acc ? acc[f.f] : acc), entity) : undefined
}
const clearFieldInfo = e => {
    if (!e) return
    if (e[$fieldInfo]) e[$fieldInfo] = undefined
    if (Array.isArray(e)) {
        e.forEach(item => {
            clearFieldInfo(item)
        })
        return
    }
    if (typeof e === "object") {
        for (let f in e) {
            clearFieldInfo(e[f])
        }
    }
}
const setRec = (e, path, i, value) => {
    const f = path[i].f
    //console.log("setrec", path,i)
    const val =
        i === path.length - 1
            ? value
            : setRec(e[f] || (Number.isInteger(path[i + 1].f) ? [] : {}), path, i + 1, value)
    let ret = e
    if (path[i].cb) path[i].cb(val, path, i)
    //console.log(e, path, i, f, value)
    if (Number.isInteger(f)) {
        ret = [...ret]
        ret[f] = val
        if (f === "_e") {
            clearFieldInfo(ret)
            ret[$type] = undefined
        }
        return ret
    }
    if (f === "_e") {
        clearFieldInfo(ret)

        return {
            ...ret,
            [f]: val,
            [$fieldInfo]: undefined,
            [$type]: undefined,
        }
    }
    //delete ret[$fieldInfo]
    //delete ret[$type]
    //clearFieldInfo(ret)
    return {
        ...ret,
        [f]: val,
        ...(ret[$fieldInfo]?{[$fieldInfo]: undefined}:{}),
        ...(ret[$type]?{[$type]: undefined}:{}),
    }
}
export const set = (entity, field, value, options) => {
    if (!entity || field == null || field === undefined) return entity
    const path = buildPath(entity, field, options)
    //console.log(field, path)
    const ret = path ? setRec(entity, path, 0, value) : entity
    //console.log("SET", field, path, value, ret)
    return ret
}
export const unset = (entity, field) => {
    const e = {}
    Object.keys(entity)
        .filter(f => f !== field)
        .forEach(f => {
            e[f] = entity[f]
        })
    if (!e.$unset) e.$unset = {}
    e.$unset[field] = ""
    if (e._e && e._e[field]) {
        delete e._e[field]
    }

    return e
}

export const getId = e => (e && e._id && e._id instanceof ObjectId ? e._id.toString() : "")

export const getTypeField = (info, field, options) => {
    if (options.language) {
        let ret = info?._i18n?.[options.language]?.[field]
        if (ret) return ret
    }
    return info[field]
}
