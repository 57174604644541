import classes from "./classes"
import { types, getEntityTypes, getFieldTypes } from "./types"
import {
    is,
    getCollection,
    getTypeInfo,
    resetInfo,
    getSettings,
    getDisplayInfo,
    getFieldDisplayInfo,
} from "./type_info"
import { getId, get, set, getLens, unset, getTypeField } from "./accessors"
import { create, load, save, isNew, remove } from "./entity"
import { addField } from "./addField"

export default {
    classes,
    types,
    getEntityTypes,
    getFieldTypes,

    is,
    getCollection,
    getTypeInfo,
    resetInfo,
    getSettings,

    set,
    unset,
    get,
    getLens,
    getTypeField,

    getId,

    create,
    load,
    save,
    remove,
    isNew,

    getDisplayInfo,
    getFieldDisplayInfo,

    addField,
}
