import React from "react"
import {useQuery, publish} from "lib"
//import DocList from "./DocList"

const GroupDocList = ({value, ...props}) => {
    const q = React.useMemo(() => ({
        collection: "cat",
        query: {
            groups: value
        }
    }) , [value])
    const [cats] = useQuery(q)
    React.useEffect(() => {
        if(!value || !cats) return
        publish("cat", cats)
    }, [value, cats])

    //console.log(value, cats)
    //if(!cats) return null
    //return <DocList {...props} value={cats}/>
    return null
}
export default GroupDocList
