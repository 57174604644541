import { positionTypes } from "./config"

const entityFields = {
    homepage: [],

    NotFound: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    page: [
        {
            name: "header",
            type: "img",
            style: "header",
            label: "Imagine Antet",
            single: true,

            default: [{ url: "/upload/node/img/2017/08/23/hd-1.jpg", w: 1920, h: 340 }],
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "docs", type: "doc", label: "Documente" },
        { name: "promoted", type: "bool", label: "Promovat" },
    ],

    document: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "subtitle", type: "text", label: "Subtitlu", display: false, multiLine: true, rows: 3 },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson,hasstate",
            refOptions: {
                searchQuery: { hascontent: 1 },
                sort: { group: 1, title: 1 },
                group: option => option.group,
                disabled: option => !option.hascontent,
            },
        },
        /*{
            name: "person",
            type: "ref",
            ref: "person",
            label: "Persoana",
            cache: "firstName,lastName,position",
        },
        {
            name: "state",
            type: "select",
            values: documentStateTypes,
            label: "Stare",
        },
        { name: "year", type: "text", label: "An" },*/
        { name: "date", type: "date", label: "Data" },
        { name: "showTime", type: "bool", label: "Afișează ora" },
        { name: "docs", type: "doc", label: "Document" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    /*elink: [
        { name: "kind", type: "text", label: "Tip Legatura" },
        { name: "src", type: "ref", ref: "document", label: "Sursa" },
        { name: "dst", type: "ref", ref: "document", label: "Destinatie" },
    ],*/
    docList: [
        {
            name: "header",
            type: "img",
            style: "header",
            label: "Imagine Antet",
            single: true,

            default: [{ url: "/upload/node/img/2017/08/23/hd-1.jpg", w: 1920, h: 340 }],
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "body", type: "html", label: "Introducere" },
        {
            name: "cat",
            type: "ref",
            ref: "category",
            label: "Categorie",
            cache: "title,path_info,hasperson,hasstate",
            renderer: "DocList",
            _visibility: true,
            refOptions: {
                searchQuery: { hascontent: 1 },
                sort: { group: 1, title: 1 },
                group: option => option.group,
                disabled: option => !option.hascontent,
            },
        },
        { name: "group", type: "text", renderer: "GroupDocList", label: "Grup" },
        { name: "position", type: "select", values: positionTypes, label: "Functia" },
        { name: "promoted", type: "bool", label: "Promovat" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],

    article: [
        {
            name: "header",
            type: "img",
            style: "header",
            label: "Imagine Antet",
            single: true,
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "date", type: "date", label: "Data" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "docs", type: "doc", label: "Documente" },
    ],
    person: [
        { name: "firstName", type: "text", label: "Prenume" },
        { name: "lastName", type: "text", label: "Nume" },
        { name: "position", type: "select", values: positionTypes, label: "Funcţie" },
        { name: "img", type: "img", label: "Imagine" },
        { name: "body", type: "html", label: "Prezentare" },
        { name: "active", type: "bool", label: "Activ" },
    ],

    category: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "hascontent", type: "bool", label: "Conținut" },
        { name: "path_info", type: "text", computed: true, label: "Pathinfo" },
        { name: "hasperson", type: "bool", label: "Persoana" },
        { name: "hasstate", type: "bool", label: "Stare" },
        {
            name: "parent",
            type: "ref",
            ref: "category",
            label: "Părinte",
            cache: "title,group,path_info",
        },
        { name: "group", type: "text", computed: true, label: "Secțiune" },
        {
            name: "alias",
            type: "ref",
            ref: "category",
            label: "Alias",
            cache: "title",
        },
        { name: "groups", type: "list", items: "text", editor:"tags", label: "Grupuri" },
    ],
    folder: [
        {
            name: "header",
            type: "img",
            style: "header",
            label: "Imagine Antet",
            single: true,

            default: [{ url: "/upload/node/img/2017/08/23/hd-1.jpg", w: 1920, h: 340 }],
        },
        { name: "title", type: "text", label: "Titlu" },
        { name: "folder", type: "computed", label: "Dosar", renderer: "Folder", _nowrap: true },
        { name: "summary", type: "text", label: "Sumar" },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
    ],

    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Elemente" },
    ],
    footer: [
        { name: "text", type: "html", label: "Text" },
        { name: "contact", type: "html", label: "Contact" },
    ],
}
export { entityFields }
