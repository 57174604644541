export const basicSettings = [
    {
        name: "_nodel",
        type: "bool",
        label: "Nu se poate șterge",
        widget: "toggle",
        require: "admin",
    },
    { name: "id", type: "text", label: "CSS ID" },
    { name: "_class", type: "text", label: "Clasa CSS" },
]
export const fieldSettings = [
    ...basicSettings,
    {
        name: "_hidden",
        type: "bool",
        label: "Ascuns",
        widget: "toggle",
        require: "admin",
    },
    { name: "_label", type: "text", label: "Etichetă" },
    { name: "_visibility", type: "bool", label: "CSS: Marchează vizibilitatea" },
    { name: "_renderer", type: "text", label: "Renderer", require: "admin" },
    { name: "region", type: "text", label: "Regiune" },
    { name: "_inner", type: "bool", label: "Container interior" },
    { name: "_inner2", type: "bool", label: "Container interior dublu" },
    //{ name: "_nowrap", type: "bool", label: "Fără container interior" },
]
//export const metaSettings = fieldSettings
export const entitySettings = basicSettings
export const nodeSettings = [
    /*{
        name: "pathauto",
        type: "bool",
        label: "Pathauto",
        widget: "toggle",
        set: (e, v) => {
            if (v) return { ...e, _c: { ...(e._c || {}), pathauto: true } }
            const _c = e._c || {}
            const { pathauto, ...rest } = _c
            return { ...e, _c: rest }
        },
    },*/
]
