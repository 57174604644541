import React from "react"
import { useHistory, FaIcon, Link } from "lib"
import { useMainMenu } from "../header/MainMenu"

const findRecursive = (menu, searchLocation) => {
    const { p, items } = menu
    if (p === searchLocation) {
        return menu
    }
    if (!items) return null

    for (let i in items) {
        let parent = findRecursive(items[i], searchLocation)
        if (parent) return parent
    }
    return null
}

const useSiblings = pathname => {
    const menu = useMainMenu()
    if (!menu) return null

    //const location = entity.path
    let toks = pathname.split("/")
    toks.pop()
    const searchLocation = toks.join("/")

    let parent = null
    for (let i in menu) {
        parent = findRecursive(menu[i], searchLocation)
        if (parent) return parent.items
        else return menu
    }
    return null
}

const renderIcon = sibling => (
    <div className="icon">
        <FaIcon icon={sibling.icon || "regular/faFilePdf"} size={64} />
    </div>
)

const Siblings = ({ entity }) => {
    const history = useHistory()
    const siblings = useSiblings(history.location.pathname)
    if (!siblings || siblings.length === 0) return null

    return (
        <div className="siblings">
            {siblings &&
                siblings.map((sibling, i) => (
                    <div key={i} className="item">
                        <Link to={sibling.p}>
                            {renderIcon(sibling)}
                            <h3>{sibling.title}</h3>
                        </Link>
                    </div>
                ))}
        </div>
    )
}
export default React.memo(Siblings)
