import React from "react"
import { Region } from "lib"
//import PageLayout from "./PageLayout"
import Breadcrumb from "./Breadcrumb"
import Siblings from "./Siblings"
import Promoted from "./Promoted"

export const HomeLayout = ({ entity, language }) => {
    //console.log(entity)
    return <Region entity={entity} language={language} dynamic />
}
export const DefaultLayout = ({ entity, language }) => {
    //console.log(entity)
    return (
        <>
            <Region region="header" entity={entity} language={language} />
            <Region region="hero" entity={entity} language={language}>
                <Breadcrumb entity={entity} />
            </Region>

            <div region-node-main="">
                <Region entity={entity} language={language} dynamic />
                <div region-right="">
                    <Siblings entity={entity} />
                </div>
                <div region-promoted="">
                    <Promoted />
                </div>
            </div>
        </>
    )
}
/*
export const DefaultLayout = ({ entity, language }) => {
    console.log(entity)
    return (
        <>
            <Region region="hero" entity={entity} language={language}>
                <Siblings entity={entity} />
            </Region>
            <Region entity={entity} language={language} dynamic />
        </>
    )
}

const HomeLayout = ({ entity, language }) => <Region entity={entity} language={language} dynamic />
*/
export const nodeLayout = {
    homepage: HomeLayout,
    //page: PageLayout,
    //article: PageLayout,
    //NotFound: PageLayout,
}
