import React from "react"
import { Link } from "lib"
import MainMenu, { useMainMenu } from "./MainMenu"
import MobileMenu from "./MobileMenu"
const Logo = props => (
    <div className="logo">
        <svg
            fill="#FFFFFF"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z" />
        </svg>
    </div>
)

const Header = ({ node, language, first }) => {
    const menu = useMainMenu()
    return (
        <header role="banner" id="header" key="header">
            <Link id="logo" to="/">
                <Logo />
                <div className="site-name">
                    <div className="line-1">Primăria Comunei</div>
                    <div className="line-2">Romuli</div>
                </div>
            </Link>

            <div className="header-in">
                <MainMenu id="main-menu" menu={menu} language={language} />
                <MobileMenu menu={menu} />
            </div>
        </header>
    )
}
export default React.memo(Header)
