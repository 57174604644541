import React from "react"
import { EntityView, Field, FaIcon, Link, useQuery } from "lib"

const qPromoted = {
    collection: "node",
    query: { promoted: 1 },
    projection: { type: 1, title: 1, thumb: 1, path: 1 },
}
const Promoted = () => {
    const [data] = useQuery(qPromoted)
    if (!data) return null

    return (
        <div className="promoted">
            {data &&
                data.map((item, i) => (
                    <EntityView entity={item} key={i} className="item" display="promoted">
                        {info => (
                            <Link to={item.path}>
                                <Field
                                    info={info}
                                    field="thumb"
                                    className="thumb"
                                    imageStyle="thumb2"
                                />
                                <div className="text">
                                    <Field info={info} field="title" tag="h3" />
                                    <div className="arrow">
                                        <FaIcon icon="faAngleRight" />
                                    </div>
                                </div>
                            </Link>
                        )}
                    </EntityView>
                ))}
        </div>
    )
}

export default React.memo(Promoted)
