import React from "react"
import { Entity, formatDate, Query } from "lib"
import { documentStateTypes } from "./config"
const _handleChangeState = (entity, entityAdmin, state) => () => {
    const e = Entity.set(entity, "state", state.val)
    Entity.save(e, { entityAdmin })
}
export const nodeTypes = {
    homepage: { label: "Acasă", _noCreate: true },
    page: {
        label: "Pagină",
        info: "Pagină generică",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },

    document: {
        label: "Document",
        info: "Document",
        searchField: "title",
        //_noCreate: true
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        presave: entity => {
            if ((!entity.cat || !entity.cat.ref || !entity.cat.hasperson) && entity.date)
                entity.year = entity.date.getFullYear()
            return entity
        },
        contextMenu: (user, entity, entityAdmin) =>
            entity.kind === "16"
                ? documentStateTypes.map(state => ({
                      label: state.label,
                      radio: true,
                      checked: entity.state === state.val,
                      handler: _handleChangeState(entity, entityAdmin, state),
                  }))
                : [],
        __init: (initTypeInfo, doc) => {
            //console.log(doc)
            let typeInfo = initTypeInfo
            if(doc?.cat?.hasperson){
                typeInfo = {
                    ...typeInfo,
                    fields: [
                        ...typeInfo.fields.slice(0, 7), // after docs
                        {
                            name: "person",
                            type: "ref",
                            ref: "person",
                            label: "Persoana",
                            cache: "firstName,lastName,position",
                        },
                        { name: "year", type: "text", label: "An" },
                        ...typeInfo.fields.slice(7), // after docs
                    ]
                }
            }
            if(doc?.cat?.hasstate){
                typeInfo = {
                    ...typeInfo,
                    fields: [
                        ...typeInfo.fields.slice(0, 7), // after docs
                        {
                            name: "state",
                            type: "select",
                            values: documentStateTypes,
                            label: "Stare",
                        },
                        ...typeInfo.fields.slice(7), // after docs
                    ]
                }
            }
            //console.log("__init", typeInfo, doc)
            switch (doc?.cat?.ref) {
            case "5e11158b00ab012e20b323dd": // Convocari sedinte
                return {
                    ...typeInfo,
                    fields: [
                        ...typeInfo.fields.slice(0, 6), // after docs
                        {
                            name: "mdate",
                            type: "date",
                            label: "Data ședinței"
                        },
                        typeInfo.fields[6],
                        {
                            name: "projects",
                            type: "list",
                            items: {
                                type: "ref",
                                ref: "document",
                                label: "Proiect de hotarâre",
                                cache: "title,subtitle,docs,materiale",
                                refOptions: {
                                    searchQuery: { "cat.ref": '624f4e9e1a8ac51d52ff2374' },
                                    sort: { date: -1, _id: -1 },
                                },
                            },
                            renderer: "DocRefList",
                            label: "Proiecte de hotarâri",
                            widget: "selector",
                            widgetProps: {
                                options: async () => {
                                    const resAttrib = await Query.select({
                                        collection: "node",
                                        query: {
                                            "cat.ref": doc.cat.ref
                                        }
                                    })
                                    const attrib = resAttrib.results
                                          .filter(s =>
                                              s._id.toString()!==doc._id.toString() &&
                                                  (s.projects??[]).length>0)
                                          .map(s => s.projects.map(p => p.ref))
                                          .reduce((acc, p) => [...acc, ...p], [])
                                    const q = {
                                        collection: "node",
                                        query: {
                                            "cat.ref": '624f4e9e1a8ac51d52ff2374'
                                        }
                                    }
                                    const res = await Query.select(q)
                                    //console.log(res)
                                    return res.results
                                        .filter(o => !attrib.includes(o._id.toString()))
                                        .map(
                                        o => ({
                                            value: {
                                                ref: o._id.toString(),
                                                title: o.title,
                                                subtitle: o.subtitle,
                                                docs: o.docs,
                                                ...(o.materiale?{materiale: o.materiale}:{})
                                            },
                                            raw: o
                                        })
                                    )
                                },
                                optionComp: (o, v) => o.value.ref===v.ref,
                                optionFields: [
                                    {
                                        label: "Data",
                                        render: o => formatDate(o.raw.date),
                                    },
                                    {
                                        label: "Proiect",
                                        render: o => (
                                            <>
                                                <h3>{o.value.title}</h3>
                                                <span>{o.value.subtitle}</span>
                                            </>
                                        )
                                    }
                                ]
                            }
                        },
                        ...typeInfo.fields.slice(7),
                    ]
                }
            case "5e11158b00ab012e20b323dc": // Hotarari
                return {
                    ...typeInfo,
                    fields: [
                        ...typeInfo.fields.slice(0, 7), // after docs
                        {
                            name: "project",
                            type: "ref",
                            ref: "document",
                            label: "Proiect de hotarâre",
                            cache: "title,subtitle,docs",
                            refOptions: {
                                searchQuery: { "cat.ref": '624f4e9e1a8ac51d52ff2374' },
                                sort: { date: -1, _id: -1 },
                                getLabel: e => `${formatDate(e?.date)} - ${e.title}`
                            },
                            renderer: "DocRef"
                        },
                        ...typeInfo.fields.slice(7),
                    ]
                }
                
            case '624f4e9e1a8ac51d52ff2374': // Proiecte de hotarari
                return {
                    ...typeInfo,
                    fields: [
                        ...typeInfo.fields.slice(0, 10), // after docs
                        {name: "materiale", type: "doc", label: "Materiale", showLabel: true},
                        ...typeInfo.fields.slice(10),
                    ]
                }
            default:
                return typeInfo
            }
        },
    },
    docList: {
        label: "Lista documente",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    article: {
        label: "Articol",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        /*{
            const types = documentTypes.filter(type => type.label === entity.cat)
            return types.length > 0 ? types[0].pathinfo : null
        },*/
    },
    folder: {
        label: "Dosar",
        info: "Dosar",
        searchField: "title",
        //_noCreate: true,
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
    },
    NotFound: {
        label: "Pagină inexistentă",
        _noCreate: true,
    },
}

export const entityTypes = {
    menu: {
        label: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        _noCreate: true,
    },
    category: {
        label: "Categorie",
        collection: "cat",
        searchField: "title",
    },

    person: {
        label: "Persoană",
        getLabel: entity => `${entity.firstName} ${entity.lastName}`,
        refOptions: {
            searchField: ["firstName", "lastName"],
        },
    },

    footer: {
        label: "Footer",
        collection: "site",
    },
    /*elink: {
        label: "ELink",
        collection: "elink",
        _noCreate: true,
    },*/
}
