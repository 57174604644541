import C from "../conf"
//import cache from "./cache"

const entity = {
    //name: "entity",
}
const field = C.LANGUAGES
    ? {
          buildPath: (p, e, path, i, info, typeInfo, buildPathRec, options) => {
              let cPath = [{ f: path[i] }]
              let value = e ? e[path[i]] : null
              if (
                  i === path.length - 1 &&
                  typeInfo.type !== "obj" &&
                  typeInfo.type !== "list" &&
                  typeInfo.type !== "ref" &&
                  typeInfo._t !== false
              ) {
                  const defLang = options?.defaultLanguage ?? C.LANGUAGES[0]
                  const lang = options?.language ?? C.LANGUAGES[0]
                  if (lang !== defLang) {
                      cPath = [{ f: "_i18n" }, { f: lang }, { f: path[i] }]
                      value = e?._i18n?.[lang]?.[path[i]]
                  }
              }
              //const item = i===path.length-1
              //  ?[{}]
              if (p.length === 0) return buildPathRec(cPath, value, typeInfo, path, i + 1, options)
              return buildPathRec(
                  [...p, ...cPath],
                  e ? e[path[i]] : null,
                  typeInfo,
                  path,
                  i + 1,
                  options
              )
          },
      }
    : {}
const node = {
    //name: "node",
}
const meta = {
    //name: "meta",
    buildPath: (p, e, path, i, info, typeInfo, buildPathRec, options) => {
        //console.log(p, e, path, i, info, typeInfo, buildPathRec)
        if (Number.isInteger(path[i])) {
            if (i === path.length - 1) return [...p, { f: path[i] }, { f: "meta" }]
            return buildPathRec(
                [...p, { f: path[i] }, { f: "meta" }, { f: path[i + 1] }],
                e && e[path[i]] && e[path[i]].meta ? e[path[i]].meta[path[i + 1]] : null,
                typeInfo,
                path,
                i + 2,
                options
            )
        }
        return buildPathRec(
            [...p, { f: "meta" }, { f: path[i] }],
            e && e.meta ? e.meta[path[i]] : null,
            typeInfo,
            path,
            i + 1,
            options
        )
    },
}
/*
const updateTypeInfo = (info, k, p, j, path, i, value) => {
    //console.log(info, k, p, j, path, i, value)
    if (k === i) return { ...info, [path[k]]: value }
    return {
        ...info,
        fieldInfo: {
            ...info.fieldInfo,
            [path[k]]: updateTypeInfo(info.fieldInfo[path[k]], k + 1, p, j, path, i, value),
        },
    }
}*/
const setting = {
    //name: "setting",
    buildPath: (p, e, path, i, info, typeInfo, buildPathRec, options) => {
        //console.log(p, e, path, i, info, typeInfo, buildPathRec)
        if (info.typeClasses.includes("entity"))
            return buildPathRec(
                [
                    ...p,
                    { f: "_c" },
                    {
                        f: path[i],
                        cb: value => {
                            console.log(p)
                            info[path[i]] = value
                        },
                    },
                ],
                info[path[i]],
                typeInfo,
                path,
                i + 1,
                options
            )

        return buildPathRec(
            [
                ...p.slice(0, p.length - 1),
                { f: "_e" },
                p[p.length - 1],
                {
                    f: path[i],
                    cb: (value, p, j) => {
                        //info._update = true
                        //info[path[i]] = value
                        //console.log(value, p, j, path, i, info, typeInfo, e, info[path[i]])
                    },
                },
            ],
            info[path[i]],
            typeInfo,
            path,
            i + 1,
            options
        )
    },
}
const classes = { entity, field, node, setting, meta }
export default classes
