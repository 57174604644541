import LinkRenderer from "./LinkRenderer"
import Link from "./Link"
import Quote from "./Quote"
import Icon from "./Icon"

import Block from "./Block"
import Doc from "./Doc"
import Slideshow from "./Slideshow"
import Slide from "./Slide"
import Action from "./Action"
import DocList from "./DocList"
import Card from "./Card"
import CardList from "./CardList"
import MinicardList from "./MinicardList"
import Minicard from "./Minicard"
import SimpleIcon from "./SimpleIcon"
import ImgRenderer from "./ImgRenderer"
import Folder from "./Folder"
import TabList from "./TabList"
import DocRef from "./DocRef"
import DocRefList from "./DocRefList"
import GroupDocList from "./GroupDocList"

const fieldRenderer = {
    Block,
    LinkRenderer,
    Link,
    Icon,
    Quote,
    doc: Doc,
    Slideshow,
    Slide,
    Action,
    DocList,
    GroupDocList,
    Card,
    CardList,
    MinicardList,
    Minicard,
    SimpleIcon,
    ImgRenderer,
    Folder,
    TabList,
    DocRef,
    DocRefList,
}
export default fieldRenderer
