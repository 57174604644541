import React from "react"
import Field from "../Field"

const Any = ({ domRef, info, value, ...props }) => {
    //console.log(value, fieldInfo)
    if (!value || info.fieldInfo.fields === undefined) return null
    return (
        <>
            {info.fieldInfo.fields
                .filter(f => f.display === undefined || f.display)
                .map((f, i) => (
                    <Field key={i} info={info} field={f.name} {...props} noadmin />
                ))}
        </>
    )
}
export default Any
